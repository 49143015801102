import styled from 'styled-components';
import SVG from 'svgs';
import {
  MAX_TABLET,
  MAX_PHONE,
  visuallyHidden,
  MAX_DESKTOP,
} from 'components/styles';

export type CarouselAlignment = 'left' | 'right';

export const CarouselHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CarouselWrapper = styled.div<{
  keepGradientEnabled: boolean;
  displayGradient: boolean;
}>`
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  display: flex;
  height: auto;
  align-self: center;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  ${({ keepGradientEnabled }) =>
    keepGradientEnabled &&
    `    mask-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 2%,
      rgba(0, 0, 0, 1) 98%,
      rgba(0, 0, 0, 0) 100%
    );`};

  @media (min-width: ${MAX_DESKTOP}) {
    ${({ displayGradient }) =>
      displayGradient &&
      `    mask-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 2%,
      rgba(0, 0, 0, 1) 98%,
      rgba(0, 0, 0, 0) 100%
    );`};
  }
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  > * {
    flex: 0 0 auto;
    scroll-snap-align: center;
  }
`;

export const Container = styled.div<{ maxCarouselWidth: string }>`
  display: flex;
  position: relative;
  align-self: center;
  width: 100%;

  @media (min-width: ${MAX_TABLET}) {
    max-width: ${({ maxCarouselWidth }) => maxCarouselWidth};
  }

  :hover {
    > :first-child {
      opacity: 1;
    }
    > :last-child {
      opacity: 1;
    }
  }
`;

const rightGradient = `linear-gradient(
  90deg,
  transparent 0%,
  rgba(0, 0, 0, 0.5) 100%
)`;

const leftGradient = `
linear-gradient(
  90deg,
  rgba(0, 0, 0, 0.5) 0%,
  transparent 100%
)`;

export const ControlButton = styled.button<{ alignment: CarouselAlignment }>`
  opacity: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: ${({ alignment }) => alignment === 'right' && '-32px'};
  left: ${({ alignment }) => alignment === 'left' && '-32px'};
  width: ${({ theme }) => theme.spacing.customSpacing('50px')};
  z-index: 100000;
  height: 100%;
  cursor: pointer;

  @media (max-width: ${MAX_DESKTOP}) {
    right: ${({ alignment }) => alignment === 'right' && 0};
    left: ${({ alignment }) => alignment === 'left' && 0};

    :hover {
      background-image: ${({ alignment }) =>
        alignment === 'right' ? rightGradient : leftGradient};
    }
  }

  @media (max-width: ${MAX_PHONE}) {
    ${visuallyHidden};
  }
`;

export const ButtonIcon = styled(SVG)`
  fill: ${({ theme, color }) =>
    color === 'light'
      ? theme.carouselControl.light
      : theme.carouselControl.dark};

  @media (max-width: ${MAX_DESKTOP}) {
    fill: ${({ theme }) => theme.carouselControl.light};
  }
`;

export const InnerCarouselWrapper = styled.div`
  display: flex;

  > div {
    margin-right: ${({ theme }) => theme.spacing.BASE_SPACING};
  }

  > :first-child {
    margin-left: ${({ theme }) => theme.spacing.BASE_SPACING};
  }

  > a {
    margin-right: ${({ theme }) => theme.spacing.BASE_SPACING};
  }
`;
